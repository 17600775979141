<template>
  <div>
    <Navbar :page="!$store.state.user.cliente ? 'Gestão de Projetos' : 'Andamento do Projeto'" video="https://www.youtube.com/watch?v=agOXg2Ti9bk" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <div class="grid grid-cols-12 gap-6 mb-2">
        <div class="col-span-12 md:col-span-3 2xl:col-span-6">
          <select v-model="tipoGrafico" class="h-10 border-none text-base font-bold text-secondary focus:ring-0">
            <option value="Geral">Gráfico Geral</option>
            <option value="Fase">Gráfico andamento por Fase</option>
          </select>
        </div>
        <div v-if="!$store.state.user.cliente" class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button @click="arquivarProjeto" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }" class="inline-block transition duration-200 bg-yellow-300 hover:bg-yellow-400 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            Novo projeto 
          </button>
        </div>
        <div v-if="!$store.state.user.cliente" class="col-span-12 md:col-span-3 2xl:col-span-2">
          <router-link :to="`/projetos/arquivadas`" class="inline-block transition duration-200 bg-gray-600 hover:bg-gray-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            <span class="inline-block">Histórico projetos</span>
          </router-link>
        </div>
        <div v-if="!$store.state.user.cliente" class="col-span-12 md:col-span-3 2xl:col-span-2">
          <router-link :to="`/projetos/fases`" type="button" class="inline-block transition duration-200 bg-yellow-300 hover:bg-yellow-400 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            Fases
          </router-link>
        </div>
      </div>
      <div v-if="tipoGrafico === 'Geral'" class="grid grid-cols-12">
        <div class="col-span-12 md:col-span-5 2xl:col-span-4">
          <apexchart v-if="resumo" height="210" type="donut" :options="optionsGeral" :series="seriesGeral"></apexchart>
        </div>
        <div class="col-span-12 md:col-span-6 py-2 px-4">
          <h2 class="text-xl mb-3 font-semibold">PROJETO: 
            <span class="font-normal">
              {{ resumo && resumo.projetoAtual && resumo.projetoAtual.nome ? resumo.projetoAtual.nome : '' }}
              <svg @click="$modal.show('editarNomeProjeto')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="inline w-5 h-5 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
              </svg>

            </span>
            </h2>
          <h2 class="text-base mb-3 font-semibold" v-if="resumo && resumo.projetoAtual && resumo.projetoAtual.inicio">Início do projeto: <span class="font-normal">{{ resumo.projetoAtual.inicio | moment("DD/MM/YYYY") }}</span></h2>
          <h2 class="text-base mb-3 font-semibold" v-if="resumo && resumo.projetoAtual && resumo.projetoAtual.fim">Previsão inicial de conclusão: <span class="font-normal">{{ resumo.projetoAtual.fim | moment("DD/MM/YYYY") }}</span></h2>
          <h2 class="text-base mb-3 font-semibold" v-if="resumo && resumo.projetoAtual && resumo.projetoAtual.inicio && resumo.projetoAtual.fim">Prazo restante (em dias): <span class="font-normal">{{ $moment(resumo.projetoAtual.fim).diff($moment(), 'days') > -1 ? $moment(resumo.projetoAtual.fim).diff($moment(), 'days') : 'Concluído' }}</span></h2>
        </div>
      </div>
      
      <div v-if="tipoGrafico === 'Fase'" class="grid grid-cols-12">
        <div class="col-span-12 md:col-span-4" v-for="fase in fases" :key="fase._id">
          <h2 class="text-base mb-2 font-semibold text-center">{{ fase.nome }}</h2>
          <apexchart v-if="getSeries(fase)" type="donut" width="300" :options="getSeries(fase).options" :series="getSeries(fase).data"></apexchart>
        </div>
      </div>
        
    </div>
    <div class="mx-4 md:mx-8 mt-2 mb-8">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <router-link v-if="!$store.state.user.cliente" :to="`/projetos/form`" type="button" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }" class="inline-block transition duration-200 bg-yellow-300 hover:bg-yellow-400 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            <span class="inline-block mr-2">Nova tarefa</span>
          </router-link>
        </div>

        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <a v-if="!$store.state.user.cliente" :href="`${url_api}/v1/${route}/exportar/${$store.state.empresa._id}?token=${$store.state.token}`" target= "_blank" rel="noreferrer" class="bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 focus:shadow-sm focus:ring-4 text-white w-full flex py-2 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mt-1 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
              </svg>
              Download tarefas 
          </a>
        </div>

        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button v-if="!$store.state.user.cliente" @click="$modal.show('importarModal')" class="bg-green-400 hover:bg-green-500 focus:bg-green-500 focus:shadow-sm focus:ring-4 text-white w-full flex py-2 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mt-1 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg> 
            Importar tarefas 
          </button>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-6 mt-2">
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button @click="$modal.show('editarSelecionadas')" v-if="!$store.state.user.cliente && selecionados.length" type="button" class="transition duration-200 bg-blue-500 hover:bg-blue-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            <span class="inline-block">Editar selecionadas</span>
          </button>
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button @click="excluirSelecionadas" v-if="!$store.state.user.cliente && selecionados.length" type="button" class="transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            <span class="inline-block">Excluir selecionadas</span>
          </button>
        </div>
      </div>
      <hr class="my-2"/>
      <div class="grid grid-cols-12 gap-3 mb-3">
        <div class="col-span-12 xl:col-span-3 mb-1 mt-1">
          <select v-model="fase" name="fase" id="fase" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
            <option default :value="null">Todos as fases</option>
            <option v-for="fase in fases" :key="fase._id" :value="fase.nome">{{ fase.nome }}</option>
          </select>
        </div>
        <div class="col-span-12 xl:col-span-3 mb-1 mt-1">
            <select v-model="nivelUrgencia" name="nivelUrgencia" id="nivelUrgencia" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
            <option default :value="null">Todos os níveis</option>
            <option value="Médio">Médio</option>
            <option value="Alto">Alto</option>
            <option value="Baixo">Baixo</option>
          </select>
        </div>
        <div class="col-span-12 xl:col-span-6 mb-1 mt-1">
            <input v-model="busca" type="text" name="busca" id="busca" placeholder="Filtrando Tarefa" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
        </div>
        <div class="col-span-12 xl:col-span-3 mb-1 mt-1">
          <select required v-model="status" name="status" id="status" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
            <option default :value="null">Todos os status</option>
            <option value="Pendente">Pendente</option>
            <option value="Programada">Programada</option>
            <option value="Em Andamento">Em Andamento</option>
            <option value="Concluido">Concluido</option>
            <option value="Atrasada">Atrasada</option>
          </select>
        </div>
        <div class="col-span-12 md:col-span-3">
            <select required v-model="tipo_responsavel" name="tipo_responsavel" id="tipo_responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option :value="null">Qualquer responsável</option>
                <option value="profissional">Profissional</option>
                <option value="cliente">Pessoas na empresa</option>
            </select>
        </div>
        <div v-if="tipo_responsavel === 'profissional'" class="col-span-12 md:col-span-4">
            <select required v-model="profissional_responsavel" name="profissional_responsavel" id="profissional_responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option :value="null">Qualquer profissional responsável</option>
                <option v-for="profissional in profissionais" :key="profissional._id" :value="profissional._id">{{ profissional.nome }}</option>
            </select>
        </div>
        <div v-if="tipo_responsavel === 'cliente'" class="col-span-12 md:col-span-4">
            <select required v-model="cliente_responsavel" name="cliente_responsavel" id="cliente_responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option :value="null">Qualquer cliente responsável</option>
                <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
            </select>
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-2">
          <button @click="start" class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
        </div>
      </div>

       <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">      
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th v-if="!$store.state.user.cliente" scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer">
                      <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      REF
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Fase
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Tarefa
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Descrição
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Responsável
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Data Início / Fim
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Status
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Evidências
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Opções
                    </th>
                  </tr>
                </thead>
                <draggable v-model="list" class="bg-white divide-y divide-gray-200" tag="tbody" @change="moveTarefas">
                  <tr v-for="item in list" :key="item._id" class="hover:bg-gray-200 cursor-pointer">
                    <td v-if="!$store.state.user.cliente" class="px-3 py-4">
                      <div class="flex items-center">
                        <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                      </div>
                    </td>
                    
                    <td class="px-2 py-4">
                      <div class="flex items-center">
                        <div class="text-sm_2 text-gray-500">
                          {{item.ref ? item.ref : ''}}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-4">
                      <div class="flex items-center">
                        <div class="text-sm_2 text-gray-500">
                          {{item.fase ? item.fase : ''}}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-4">
                      <div class="flex items-center">
                        <div class="text-sm_2 text-gray-500">
                          {{item.nome ? item.nome : ''}}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-4">
                      <div class="flex w-full items-center">
                        <div class="text-sm_2 text-gray-500">
                          {{item.descricao ? item.descricao : ''}}
                        </div>
                      </div>
                    </td>

                    <td  class="px-2 py-4">
                      <div class="flex items-center">
                        <div v-if="item.tipo_responsavel === 'consultor'" class="text-sm font-semibold text-gray-500">
                          Consultor
                        </div>
                        <div v-if="item.profissional_responsavel" class="text-sm font-semibold text-gray-500">
                          {{ item.profissional_responsavel && item.profissional_responsavel.length ? item.profissional_responsavel.map(r => r.nome).join(', ') : '' }}
                        </div>
                        <div v-if="item.cliente_responsavel" class="text-sm font-semibold text-gray-500">
                          {{ item.cliente_responsavel && item.cliente_responsavel.length ? item.cliente_responsavel.map(r => r.nome).join(', ') : '' }}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-4">
                      <div class="flex items-center">
                        <div v-if="item.inicio || item.fim" class="text-sm_2 text-gray-500">
                          <span v-if="item.inicio">{{ item.inicio | moment("DD/MM/YYYY") }} </span> - <span v-if="item.fim">{{ item.fim | moment("DD/MM/YYYY") }}</span>
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-4">
                      <div class="flex items-center">
                        <div v-if="item.status === 'Pendente'" class="text-sm font-semibold text-pendente">
                          {{ item.status ? item.status: ''}}
                        </div>
                        <div v-else-if="item.status === 'Em Andamento'" class="text-sm font-semibold text-andamento">
                          {{ item.status ? item.status: ''}}
                        </div>
                        <div v-else-if="item.status === 'Programada'" class="text-sm font-semibold text-indigo-600">
                          {{ item.status ? item.status: ''}}
                        </div>
                        <div v-else-if="item.status === 'Atrasada'" class="text-sm font-semibold text-danger">
                          {{ item.status ? item.status: ''}}
                        </div>
                        <div v-else class="text-sm font-semibold text-concluido">
                          {{ item.status ? item.status: ''}}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-4">
                      <span class="text-gray-800 text-sm font-medium mr-2">QTD: <span :class="item.evidencias.length > 0 ? 'text-red-600': 'text-gray-800'">{{ item.evidencias.length }}</span></span>
                      <button
                        @click="openModalEvidencias(item)"
                        class="text-white text-sm hover:bg-yellow-400 bg-yellow-300 rounded py-1 my-1 px-2">
                        Evidências
                      </button>
                    </td>

                    <td class="px-2 py-4 text-sm font-medium">

                      <button
                        v-if="!$store.state.user.cliente"
                        @click="openModal(item._id)"
                        class="text-white text-sm hover:bg-blue-500 bg-blue-700 rounded py-1 mx-1 my-1 px-2">
                        Editar
                      </button>

                      <button
                        v-if="!$store.state.user.cliente"
                        @click="remove(item._id)" 
                        class="text-white text-sm hover:bg-red-500 bg-red-700 rounded py-1 mx-1 my-1 px-2">
                        Excluir
                      </button>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="editarSelecionadas" :width="700" :adaptive="true" :height="'auto'">
      <div class="px-5 py-8">
        <h2 class="text-2xl font-bold mb-5"> Editar as {{ selecionados.length }} tarefas selecionadas </h2>
        <div class="grid grid-cols-12 gap-4 gap-y-6">
          <div class="col-span-12 md:col-span-6">
            <label for="inicio" class="block text-sm font-medium">Data Início</label>
            <datepicker v-model="modal.inicio" :language="ptBR" name="inicio" input-class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
          </div>  
          <div class="col-span-12 md:col-span-6">
            <label for="fim" class="block text-sm font-medium">Data Fim</label>
            <datepicker v-model="modal.fim" :language="ptBR" name="fim" input-class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
          </div>
          <div class="col-span-12">
            <label for="status" class="block text-sm font-medium">Status</label>
            <select required v-model="modal.status" name="status" id="status" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option value="Pendente">Pendente</option>
                <option value="Programada">Programada</option>
                <option value="Em Andamento">Em Andamento</option>
                <option value="Concluido">Concluido</option>
                <option value="Atrasada">Atrasada</option>
            </select>
          </div>
          <div class="col-span-12 md:col-span-12">
            <span class="text-sm font-medium">Nível de Urgência da tarefa</span>
            <div class="mt-2">
                <label for="Baixo" class="inline-flex items-center">
                    <input type="radio" class="form-radio" id="Baixo" name="nivelUrgencia" v-model="modal.nivelUrgencia" value="Baixo">
                    <span class="ml-2">1 - Baixo</span>
                </label>
                <label for="Médio" class="inline-flex items-center ml-6">
                    <input type="radio" class="form-radio" id="Médio" name="nivelUrgencia" v-model="modal.nivelUrgencia" value="Médio">
                    <span class="ml-2">2 – Médio</span>
                </label>
                <label for="Alto" class="inline-flex items-center ml-6">
                    <input type="radio" class="form-radio" id="Alto" name="nivelUrgencia" v-model="modal.nivelUrgencia" value="Alto">
                    <span class="ml-2">3 - Alto</span>
                </label>
            </div>
          </div> 
          <div class="col-span-12 md:col-span-6">
            <label for="tipo_responsavel" class="block text-sm font-medium">Tipo Responsável</label>
            <select required v-model="modal.tipo_responsavel" name="tipo_responsavel" id="tipo_responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option value="profissional">Profissional</option>
                <option value="cliente">Pessoas na empresa</option>
            </select>
          </div>
          <div v-if="modal.tipo_responsavel === 'profissional'" class="col-span-12 md:col-span-6">
            <label for="profissional_responsavel" class="block text-sm font-medium">Responsável</label>
            <select required v-model="modal.profissional_responsavel" name="profissional_responsavel" id="profissional_responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option v-for="profissional in profissionais" :key="profissional._id" :value="profissional._id">{{ profissional.nome }}</option>
            </select>
          </div>
          <div v-if="modal.tipo_responsavel === 'cliente'" class="col-span-12 md:col-span-6">
            <label for="cliente_responsavel" class="block text-sm font-medium">Responsável</label>
            <select required v-model="modal.cliente_responsavel" name="cliente_responsavel" id="cliente_responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
            </select>
          </div>
        </div>
        <div class="grid grid-cols-2 mt-5">
            <div class="text-left">
                <button @click="$modal.hide('editarSelecionadas')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
            </div>
            <div class="text-right">
                <button @click="saveSelecionadas" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400">
                    Salvar
                </button>
            </div>
        </div>
      </div>
    </modal>
    <modal name="novoProjeto" :adaptive="true" :height="'auto'" :clickToClose="false">
      <div id="idmodal" class="px-3 py-5 overflow-auto h-full md:h-auto">
        <h2 class="text-xl font-semibold mb-3"> Criação novo projeto </h2>
        <form action="#" method="POST">
          <label for="nomeProjeto" class="block text-sm font-medium">Nome Projeto</label>
          <input v-model="novoProjeto.nome" type="text" name="nomeProjeto" id="nomeProjeto" class="mt-2 mb-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
          <label class="text-sm font-medium mt-2 mr-2">Fases do Projeto</label>
          <button @click="novoProjeto.fases.push({nome: ''})" type="button" class="inline-flex justify-center py-2 px-4 mb-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
              Adicionar Fase
          </button>
          <div v-for="(item, idx) in novoProjeto.fases" :key="idx" class="grid grid-cols-12 gap-x-2">
            <div class="col-span-12 md:col-span-11">
              <input v-model="item.nome" type="text" name="faseNome" id="faseNome" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
            </div>
            <div class="col-span-12 md:col-span-1 justify-self-end">
              <button type="button" @click="novoProjeto.fases.splice(idx, 1)" class="text-white text-sm hover:bg-red-600 bg-red-500 rounded py-2 px-2 mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        
          <button @click="$modal.hide('novoProjeto')" type="button" class="mt-3 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
              Voltar
          </button>
      
          <button @click="saveNovoProjeto" type="button" class="mt-3 ml-2 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-500">
              Salvar
          </button>
          
        </form>
      </div>
    </modal>
    <modal name="evidenciasModal" :width="980" :height="'auto'" :adaptive="true" :reset="true">
      <div v-if="tarefa" id="idmodal" class="px-2 py-2 overflow-y-auto h-full">
        <h2 class="text-xl font-semibold mb-1"> Evidências </h2>
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <th scope="col" class="px-2 py-1 text-left text-xs font-medium text-gray-500 tracking-wider">Documento</th>
            <th scope="col" class="px-2 py-1 text-left text-xs font-medium text-gray-500 tracking-wider">Opções</th>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(doc, index) in tarefa.evidencias" :key="doc.filename">
              <td class="px-2 py-1 text-sm whitespace-nowrap">
                {{doc.originalname}}
              </td>
              <td class="px-2 py-1 whitespace-nowrap">
                <a 
                  type="button"
                  target="_blank"
                  :href="`${url_api}/upload?mimetype=${doc.mimetype}&filename=${doc.filename}&folder=empresas`"
                  class="mr-1 inline-flex justify-center py-1 px-3 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400">
                  Visualizar
                </a>
                <button @click="excluirEvidencia(index)" class="mr-1 inline-flex justify-center py-1 px-3 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400" type="button">Excluir</button>
              </td>
            </tr>
          </tbody>
        </table>
        <hr class="py-1" />
        <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="update"></vue-dropzone>
        <button @click="$modal.hide('evidenciasModal')" type="button" class="float-right inline-flex justify-center py-2 px-6 mt-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
          Confirmar
        </button>
      </div>
    </modal>
    <modal name="tarefaModal" :width="1200" :adaptive="true" :height="'auto'" :clickToClose="false">
      <div id="idmodal" class="px-3 py-5 overflow-auto h-full md:h-auto">
        <form action="#" method="POST">
          <div class="px-3 pb-4">
              <div class="grid grid-cols-12 gap-x-2 gap-y-4">
                  <div class="col-span-12 ">
                    <div class="flex items-center">
                      <label for="ref" class="mr-1">
                        Ref da tarefa
                      </label>
                      <input v-model="form.ref" id="ref" type="text" class="w-14 text-center focus:ring-yellow-400 focus:border-yellow-400 shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                  </div>
                  <div class="col-span-12 md:col-span-3">
                      <label for="nome" class="block text-sm font-medium">Nome da tarefa</label>
                      <textarea v-model="form.nome" rows="1" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                  </div>
                  <div class="col-span-12 md:col-span-3">
                      <label for="status" class="block text-sm font-medium">Status</label>
                      <select required v-model="form.status" name="status" id="status" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                          <option value="Pendente">Pendente</option>
                          <option value="Programada">Programada</option>
                          <option value="Em Andamento">Em Andamento</option>
                          <option value="Concluido">Concluido</option>
                          <option value="Atrasada">Atrasada</option>
                      </select>
                  </div>
                  <div class="col-span-12 md:col-span-3">
                      <label for="inicio" class="block text-sm font-medium">Data Início</label>
                      <input type="date" v-model="form.inicio" id="inicio" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                  </div>  
                  <div class="col-span-12 md:col-span-3">
                      <label for="fim" class="block text-sm font-medium">Data Fim</label>
                      <input type="date" v-model="form.fim" id="fim" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                  </div>
                  <div class="col-span-12 md:col-span-6">
                      <span class="text-sm font-medium">Nível de relevância</span>
                      <div class="mt-2">
                          <label for="Baixo" class="inline-flex items-center">
                              <input type="radio" class="form-radio" id="Baixo" name="nivelUrgencia" v-model="form.nivelUrgencia" value="Baixo">
                              <span class="ml-2">1 - Baixo</span>
                          </label>
                          <label for="Médio" class="inline-flex items-center ml-6">
                              <input type="radio" class="form-radio" id="Médio" name="nivelUrgencia" v-model="form.nivelUrgencia" value="Médio">
                              <span class="ml-2">2 - Médio</span>
                          </label>
                          <label for="Alto" class="inline-flex items-center ml-6">
                              <input type="radio" class="form-radio" id="Alto" name="nivelUrgencia" v-model="form.nivelUrgencia" value="Alto">
                              <span class="ml-2">3 - Alto</span>
                          </label>
                      </div>
                  </div> 
                  <div class="col-span-6 md:col-span-6">
                      <label for="fase" class="block text-sm font-medium">Fase no projeto</label>
                      <select required v-model="form.fase" name="fase" id="fase" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                          <option v-for="fase in fases" :key="fase._id" :value="fase.nome">{{ fase.nome }}</option>
                      </select>
                  </div>
                  <div class="col-span-12 md:col-span-4">
                      <label for="descricao" class="block text-sm font-medium">Descrição</label>
                      <textarea v-model="form.descricao" type="text" name="descricao" id="descricao" rows="4" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
                  </div> 
                  <div class="col-span-12 md:col-span-8">
                      <label for="refLegal" class="block text-sm font-medium">Detalhes</label>
                      <textarea v-model="form.refLegal" type="text" name="refLegal" id="refLegal" rows="4" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
                  </div>
                  
                  <div class="col-span-12 md:col-span-6">
                      <label for="tipo_responsavel" class="block text-sm font-medium">Perfil do responsável</label>
                      <select required v-model="form.tipo_responsavel" name="tipo_responsavel" id="tipo_responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                          <option value="consultor">Marcar consultor como responsável</option>
                          <option value="profissional">Profissional</option>
                          <option value="cliente">Pessoas na empresa</option>
                      </select>
                      
                  </div>
                  <div v-if="form.tipo_responsavel === 'profissional'" class="col-span-12 md:col-span-6">
                      <label for="profissional_responsavel" class="block text-sm font-medium">Responsável</label>

                      <multiselect 
                        v-model="form.profissional_responsavel" 
                        label="nome" 
                        trackBy="_id" 
                        :options="profissionais" 
                        id="profissional_responsavel" 
                        :multiple="true" 
                        :close-on-select="false" 
                        :taggable="true" 
                        placeholder="selecione os profissionais" 
                        selectedLabel="selecionado" 
                        deselectLabel="Pressione Enter para remover" 
                        selectLabel="Pressione Enter para selecionar" 
                        class="mt-2" 
                      />

                  </div>
                  <div v-if="form.tipo_responsavel === 'cliente'" class="col-span-12 md:col-span-6">
                      <label for="cliente_responsavel" class="block text-sm font-medium">Responsável</label>

                      <multiselect 
                        v-model="form.cliente_responsavel" 
                        label="nome" 
                        trackBy="_id" 
                        :options="pessoas" 
                        id="cliente_responsavel" 
                        :multiple="true" 
                        :close-on-select="false" 
                        :taggable="true" 
                        placeholder="selecione os resposáveis" 
                        selectedLabel="selecionado" 
                        deselectLabel="Pressione Enter para remover" 
                        selectLabel="Pressione Enter para selecionar" 
                        class="mt-2" 
                      />
                  </div>
              </div>
          </div>
          
          <div class="grid grid-cols-2">
              <div class="px-5 py-3 text-left sm:px-6">
                  <button @click="$modal.hide('tarefaModal')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                      Voltar
                  </button>
              </div>
              <div class="px-5 py-3 text-right sm:px-6">
                  <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                      Salvar
                  </button>
              </div>
          </div>
        </form>
      </div>
    </modal>
    <modal name="editarNomeProjeto" :adaptive="true" :height="'auto'">
      <div class="px-3 py-3">
        <h2 class="text-xl font-bold mb-2"> Editar nome Projeto </h2>
        <div class="grid grid-cols-12 gap-x-2 gap-y-4">
          <div class="col-span-12">
              <label for="nome" class="block text-sm font-medium">Nome Projeto</label>
              <input v-model="formNomeProjeto" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
          </div>
        </div>
        <div class="grid grid-cols-2 mt-5">
          <div class="text-left">
              <button @click="$modal.hide('editarNomeProjeto')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                  Voltar
              </button>
          </div>
          <div class="text-right">
              <button @click="saveEditNomeProjeto" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                  Salvar
              </button>
          </div>
        </div>
      </div>
    </modal>
    <modal name="importarModal" :adaptive="true" :height="'auto'">
        <div class="px-5 py-5">
          <h2 class="text-xl font-bold mb-2"> Importar base</h2>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
                <a target= "_blank" rel="noreferrer" :href="`/arquivo-modelo-13.csv`" class="text-sm underline">Exemplo arquivo modelo importação</a>
            </div>
            <div class="col-span-12">
              <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptionsImport" @vdropzone-sending="send" v-on:vdropzone-success="updateTarefas">
              </vue-dropzone>
            </div>
          </div>
        </div>
      </modal>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale';
import draggable from 'vuedraggable';

export default {
  components: {
    Datepicker,
    vueDropzone,
    draggable
  },
  data() {
    return {
      ptBR: ptBR,
      route: 'tarefas',
      tipoGrafico: 'Geral',
      fases: [],
      selecionados: [],
      optionsGeral: {
        chart: {
          id: 'chartGeral',
          type: 'donut',
        },
        labels: ["Pendentes", "Concluídos", "Andamento", "Atrasadas", "Programada"]
      },
      modal: {
        inicio: null,
        fim: null,
        status: "Pendente",
        tipo_responsavel: '',
        nivelUrgencia: 1,
        profissional_responsavel: null,
        cliente_responsavel: null
      },
      novoProjeto: {
        nome: '', 
        fases: [
          { nome: 'Preparação e Diagnósticos' },
          { nome: 'Conscientização e Treinamentos' },
          { nome: 'Mapeamento e Análises' },
          { nome: 'Planejamento e Implementação' },
          { nome: 'Finalização e Entregas' },
        ],
      },
      tarefa: null,
      formNomeProjeto: '',
      form: {
        nome: "",
        descricao: "",
        nivelUrgencia: 1,
        inicio: null,
        fim: null,
        status: "Pendente",
        refLegal: "",
        fase: "",
        tipo_responsavel: '',
        profissional_responsavel: null,
        cliente_responsavel: null
      },
      pessoas: [],
      profissionais: [],
      seriesGeral: [0, 0, 0],
      list: [],
      busca: '',
      resumo: null,
      status: null,
      fase: null,
      nivelUrgencia: null,
      tipo_responsavel: null,
      profissional_responsavel: null,
      cliente_responsavel: null,
      total: 0,
      page: 1,
      perPage: 20,
      limit: 20,
      dropzoneOptionsImport: this.$http.getDropzoneConfig(
        `v1/tarefas/importar-csv`,
        'post',
      {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: 'Clique ou arraste seu arquivo aqui',
        dictRemoveFile: 'Remover'
      }),
      dropzoneOptions: this.$http.getDropzoneConfig(
        `v1/upload`,
        'post',
        {
          maxFiles: null,
          addRemoveLinks: true,
          capture: true,
          dictDefaultMessage: 'Clique ou arraste o arquivo aqui',
          dictRemoveFile: 'Remover'
        }
      ),
    }
  },
  methods: {

    async start() {
      this.limit = this.perPage;
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { busca: this.busca, tipo_responsavel: this.tipo_responsavel, profissional_responsavel: this.profissional_responsavel, cliente_responsavel: this.cliente_responsavel, status: this.status, fase: this.fase, nivelUrgencia: this.nivelUrgencia, limit: this.limit });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async moveTarefas({ moved }){
      //const oldRef = moved.element.ref;
      const newRef = moved.newIndex + 1;
      const tarefa = moved.element._id;

      console.log("tarefa", moved.element.ref);
      console.log("newRef", newRef);

      await this.$http.post(`/v1/${this.route}/moverTarefas`,{ tarefa, newRef });
      await this.start();
    },

    async update(file, response) {
      this.$refs.doc.removeFile(file);
      this.tarefa.evidencias.push(response.file);
      const req = await this.$http.put(`/v1/${this.route}/evidencias`, this.tarefa);
      if (req.data.success) {
          this.$vToastify.success("Adicionado com sucesso!");
      } else {
          this.$vToastify.error(req.data.err);
      }
    },

    updateTarefas(file, response) {
      if(response.success) this.$vToastify.success("Importando! Quando finalizado você será alertado no email.");
      else this.$vToastify.error(response.err);
      this.$modal.hide('importarModal');
      this.start();
    },

    send(file, xhr, formData) {
      formData.append('empresa', this.$store.state.empresa && this.$store.state.empresa._id ? this.$store.state.empresa._id : null);
    },

    async excluirEvidencia(index) {
      this.tarefa.evidencias.splice(index, 1);
      const req = await this.$http.put(`/v1/${this.route}/evidencias`, this.tarefa);
      if (req.data.success) {
          this.$vToastify.success("Removido com sucesso!");
      } else {
          this.$vToastify.error(req.data.err);
      }
    },

    async grafico() {
      const resReq = await this.$http.post(`/v1/${this.route}/resumo`);

      this.seriesGeral = [resReq.data.pendentes, resReq.data.concluidos, resReq.data.andamento, resReq.data.atrasadas, resReq.data.programada];
      this.resumo = resReq.data;

      if(resReq.data.projetoAtual && resReq.data.projetoAtual.nome) {
        this.formNomeProjeto = resReq.data.projetoAtual.nome;
      }

      const fasesReq = await this.$http.post(`/v1/tarefas/fases/list`, { all: true });
      this.fases = fasesReq.data.data;
      
    },

    getSeries(fase){
      return { options: { chart: { type: 'donut' }, labels: ["Pendentes", "Concluídos", "Andamento", "Atrasadas", "Programada"] }, data: [fase.pendente ? fase.pendente : 0 , fase.concluido ? fase.concluido : 0, fase.andamento ? fase.andamento: 0, fase.atrasadas ? fase.atrasadas : 0, fase.programada ? fase.programada : 0]};
    },

    async saveSelecionadas() {
      const req = await this.$http.put(`/v1/${this.route}/selecionados`, { tarefa: this.modal, selecionados: this.selecionados });
      
      if (req.data.success) {
          this.$vToastify.success("Salvo com sucesso!");
          this.$modal.hide('editarSelecionadas');
          this.start();
          this.grafico();
          this.selecionados = [];
          this.modal = { inicio: null, fim: null, status: "Pendente", tipo_responsavel: 'cliente', nivelUrgencia: 1, profissional_responsavel: null, cliente_responsavel: null };
      } else {
          this.$vToastify.error(req.data.err);
      }

    },

    async save() {

      if(this.form.tipo_responsavel === 'consultor') {
        this.form.cliente_responsavel = null;
        this.form.profissional_responsavel = null;
      }

      if(this.form.tipo_responsavel === 'profissional') {
        this.form.cliente_responsavel = null;
      }

      if(this.form.tipo_responsavel === 'cliente') {
        this.form.profissional_responsavel = null;
      }

      const reqData = await this.$http.post(`/v1/${this.route}/analisar-data`, this.form);

      if(reqData.data > 0 ) {
        this.$confirm({
          title: 'Atenção: Data ocupada',
          message: `Já existe tarefa agendada para este responsável nesta data. Programar mesmo assim?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if(!confirm) return;
            const req = await this.$http.put(`/v1/${this.route}`, this.form);
            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$modal.hide('tarefaModal');
                this.start();
                this.grafico();
            } else {
                this.$vToastify.error(req.data.err);
            }
          }
        })
        return 
      }
      
      const req = await this.$http.put(`/v1/${this.route}`, this.form);
      
      if (req.data.success) {
          this.$vToastify.success("Salvo com sucesso!");
          this.$modal.hide('tarefaModal');
          this.start();
          this.grafico();
      } else {
          this.$vToastify.error(req.data.err);
      }
      
    },

    async saveEditNomeProjeto (){
      if(!this.$store.state.empresa || !this.$store.state.empresa._id) return this.$vToastify.error("Selecione a empresa");

      const req = await this.$http.put(`/v1/empresas`, { _id: this.$store.state.empresa._id, projetoAtual: this.formNomeProjeto });
      
      if (req.data.success) {
          this.$vToastify.success("Salvo com sucesso!");
          this.$modal.hide('editarNomeProjeto');
          this.grafico();
      } else {
          this.$vToastify.error(req.data.err);
      }
    },
    
    async remove(id) {
      this.$confirm({
        title: 'Remover tarefa',
        message: `Deseja remover a tarefa selecionada ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.delete(`/v1/${this.route}/${id}`);
          this.$vToastify.success("Removido com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },

    async excluirSelecionadas(){
      this.$confirm({
        title: 'Remover tarefas selecionadas',
        message: `Deseja remover as tarefas selecionadas ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/selecionados/deletar`,{ selecionados: this.selecionados });
          this.$vToastify.success("Removido com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },

    async arquivarProjeto() {
      this.$confirm({
        title: 'Iniciar novo projeto',
        message: `É necessário arquivar o projeto atual. Confirma ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/arquivar`, { nome: this.resumo.projetoAtual && this.resumo.projetoAtual.nome ? this.resumo.projetoAtual.nome : '' });
          this.$vToastify.success("Restauradas e arquivadas com sucesso!");
          this.start();
          this.grafico();
          this.$modal.show('novoProjeto');
        }
      })
    },

    async saveNovoProjeto(){
      if(!this.novoProjeto.nome) return this.$vToastify.error("Digite o nome do novo Projeto");
      const req = await this.$http.post(`/v1/${this.route}/novoProjeto`, this.novoProjeto);
      if (req.data.success) {
          this.$vToastify.success("Novo projeto em vigor. Crie as novas tarefas do projeto!");
          this.$modal.hide('novoProjeto');
          this.$store.commit('setEmpresa', req.data.empresa);
          this.start();
          this.grafico();
      } else {
        this.$vToastify.error(req.data.err);
      }
    },

    async openModal(id){
      const req = await this.$http.get(`/v1/${this.route}/${id}`);
      this.form = req.data;
      if(this.form.inicio) this.form.inicio = this.$moment(this.form.inicio).format('YYYY-MM-DD');
      if(this.form.fim) this.form.fim = this.$moment(this.form.fim).format('YYYY-MM-DD');
      this.$modal.show('tarefaModal');
    },

    async openModalEvidencias(tarefa){
      this.tarefa = tarefa;
      this.$modal.show('evidenciasModal');
    },

    async selecionarTodos(){
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { all: true, busca: this.busca, tipo_responsavel: this.tipo_responsavel, profissional_responsavel: this.profissional_responsavel, cliente_responsavel: this.cliente_responsavel, status: this.status, fase: this.fase, nivelUrgencia: this.nivelUrgencia  });
      this.selecionados = listReq.data.data;
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    async handleScroll () {
      if(document.body.offsetHeight - (window.scrollY + window.innerHeight) < 180 && this.limit < this.total){
        this.limit += 20;
        const listReq = await this.$http.post(`/v1/${this.route}/list`, { busca: this.busca, tipo_responsavel: this.tipo_responsavel, profissional_responsavel: this.profissional_responsavel, cliente_responsavel: this.cliente_responsavel, status: this.status, fase: this.fase, nivelUrgencia: this.nivelUrgencia, limit: this.limit });
        this.list = listReq.data.data;
        this.total = listReq.data.total;
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async beforeMount() {

    this.grafico();
    this.start();
    
    const pessoasReq = await this.$http.post(`/v1/pessoas/list`, { all: true });
    this.pessoas = pessoasReq.data.data;

    const profissionaisRef = await this.$http.post(`/v1/profissionais/listProfissionaisEmpresa`); 
    this.profissionais = profissionaisRef.data.data;

    if (this.$route.query.tarefa) {
      this.openModal(this.$route.query.tarefa)
    }
  },
}
</script>